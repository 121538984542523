<template>
  <secondary-page-layout :title="title">
    <div :style="{width: `${pageWidth}%`}">
      <pdf
          class="mb-10rem"
          :src="loadingTask"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          ref="pdfDocument"
      ></pdf>
    </div>
    <div class="zoom__controls">
      <button class="zoom" @click="zoomIn">+</button>
      <button class="zoom" @click="zoomOut">-</button>
    </div>
    <div class="buttons-wrapper">
      <button class="bordered-btn" @click="shareViaWebShare">Поделиться</button>
      <button class="primary-btn" @click="toMain">На главную</button>
    </div>
  </secondary-page-layout>
</template>
<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf
  },
  data() {
    return {
      pageWidth: 100,
      loadingTask: undefined,
      currentPage: 0,
      pageCount: 0,
      file: undefined,
      base64: undefined,
      errorMessage: undefined,
      userAgent: undefined
    }
  },
  computed:{
    apiUrl() {
      return process.env.VUE_APP_BACKEND_URL
    },
    agreementId() {
      return Number(this.$route.params.agreement)
    },
    webShareApiSupported() {
      return navigator.share
    },
    title(){
      return this.$route.query?.title || "Печатная форма"
    }
  },
  mounted() {
    window.zoomIn = this.zoomIn;
    window.zoomOut = this.zoomOut;
  },
  async created() {
    this.userAgent = navigator.userAgent.toLowerCase()
    let loader = this.$loading.show({});

    const { data } = await axios.get(`${this.apiUrl}/api/policies/download/${this.agreementId}`, { responseType: 'blob' });

    this.base64 = await this.blobToBase64(data)

    let fileURL = URL.createObjectURL(data);

    this.file = new File([data], `${this.agreementId}.pdf`, { type: 'application/pdf' });

    this.loadingTask = pdf.createLoadingTask(fileURL, {
      onProgress: () => {
        loader.hide();
      }
    })
  },
  methods:{
    blobToBase64(blob){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    shareViaWebShare() {

      if(window.Android) {
        try {
          const base64 =  this.base64.split(',')[1];

          window.Android.handleDocument(base64, `${this.agreementId}.pdf`, 'share');
        } catch(e) {
          this.errorMessage = e?.message || e
        }

      } else if(webkit?.messageHandlers?.handleDocument){
        try {
          const base64 =  this.base64.split(',')[1];

          webkit?.messageHandlers?.handleDocument.postMessage({ base64, filename:`${this.agreementId}.pdf`, action:'share' })
        } catch(e) {
          this.errorMessage = e?.message || e
        }
      }else {
        try{
          if(navigator.canShare && navigator.canShare({ files: [this.file] })) {
            navigator.share({
              text: 'Печатная версия полиса',
              title: this.agreementId,
              files: [this.file],
            });
          } else {
            this.errorMessage = 'browser does not support share'
            alert(this.errorMessage)
          }
        } catch (e) {
          this.errorMessage = e?.message || e
          alert(this.errorMessage)
        }
      }

    },
    toMain() {
      this.$router.push('/')
    },
    zoomIn() {
      this.pageWidth += 10;
    },
    zoomOut() {
      this.pageWidth -= 10;
    }
  },

}

</script>

<style scoped>
.buttons-wrapper{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: rgba(255,255,255);
}
.buttons-wrapper>button {
  height: 40px;
  width: 350px;
  margin: 2rem auto;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.primary-btn {
  background: rgba(50, 168, 82, 0.8);
}
.bordered-btn{
  background: rgba(50, 168, 82, 0.1);

}

.mb-10rem{
  margin-bottom: 10rem;
}

.zoom__controls {
  position: fixed;
  bottom: 170px;
  right: 0;

  width: 52px;
  height: 115px;
}

.zoom {
  margin: 5px 0;
  background-color: white;
  font-size: 28px;
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 50%;
  color: rgba(50, 168, 82, 0.8);;
  cursor: pointer;
}
</style>